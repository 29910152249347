<template>
    <b-overlay :show='showLoader'>
        <BlueBackground style="top: 2rem;"/>
        <b-row v-if="serviceList && serviceList.length > 0" align="center">
            <b-col v-for="service in serviceList" :key="service.id" sm="12" md="6" lg="4" class="mb-2 d-flex">
                <service-card :service="service"/>
            </b-col>
        </b-row>
        <h1 v-else class="text-center pt-4">Ni podatkov za prikaz</h1>
        <pagination v-if="pagination" v-model="pagination" class="mt-1"/>
        <news-carousel class="mt-2" />
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay} from 'bootstrap-vue'
    import ServiceCard from '@/views/Components/ServiceCard'
    import Pagination from '@/views/Components/Pagination'
    import BlueBackground from '@/views/Components/BlueBackground'
    import newsCarousel from '@/views/Components/NewsCarousel'
    export default {
        components: {BlueBackground, Pagination, ServiceCard, BCol, BRow, BOverlay, newsCarousel},
        data() {
            return {
                showLoader: false,
                serviceList: [],
                pagination: null,
                created: false
            }
        },
        methods: {
            getQueryParams() {
                const page = this.$route.query.page ?? 1
                const perPage = this.$route.query.perPage ?? 12
                const searchQuery = this.$route.query.search ?? ''

                const pageQueryParam = (page ? `&page=${page}&` : '')
                const perPageQueryParam = (perPage ? `&perPage=${perPage}&` : '')
                const serachQueryParam = (searchQuery ? `&search=${searchQuery}&` : '')

                const p = [
                    pageQueryParam,
                    perPageQueryParam,
                    serachQueryParam
                ]

                return p.join('')
            },
            async loadServices() {
                const thisIns = this
                thisIns.showLoader = true

                const queryParams = this.getQueryParams()

                await thisIns.$http.get(`/api/user/v1/es/services/?${queryParams}`)
                    .then(res => {
                        this.serviceList = res.data.items ?? []
                        this.pagination = res.data.pagination
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju storitev je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        async mounted() {
            this.$scrollToTop()
            await this.loadServices()
            this.created = true
        },
        watch: {
            async 'pagination.current_page'() {
                if (!this.created) return
                const currentPage = (this.pagination ? this.pagination.current_page : 1)
                if (currentPage !== 0 && this.$route.fullPath !== `/storitve?page=${currentPage}`) {
                    await this.$router.replace({path: this.$route.fullPath, query:  {page: currentPage}})
                    await this.loadServices()
                }
            },
            '$route.query.search'(val) {
                if (val) {
                    this.loadServices()
                }
            }
        }
    }
</script>
<style>
.carousel-indicators li {
    background-color: black;
}
</style>
