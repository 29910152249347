<template>
    <div class="category-card mb-1 cursor-pointer" v-on:click="$router.push({ name: 'service', params: {service_id: service.id, is_index: false }})">
        <a class="p-0">
            <b-img class="card-img" fluid :src="`/api/user/v1/image/thumbnail_${service.main_photo}`"/>
        </a>
        <div class="service-header d-flex justify-content-start">
            <div class="title text-left">{{ service.title }}</div>
        </div>
        <div class="service-footer d-flex justify-content-between align-items-center">
            <div>
                <a class="p-0">
                    > več o tem
                </a>
            </div>
            <div class="date">
                <span>{{ dayjs(service.date_added).format('DD.MM') }}</span>
                <span style="font-weight: 400;">{{ dayjs(service.date_added).format('.YYYY') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {BImg} from 'bootstrap-vue'
    export default
        {
            components: {BImg},
            props: {
                service: {
                    type: Object,
                    required: true
                }
            }
        }
</script>

<style scoped>
.card-img {
  border-radius:0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.category-card {
  background-color: transparent;
  border-radius:0;
  width: 100%;
  height: 300px;
  position: relative;
}
.service-header {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.title {
  text-align: left;
  padding: 1rem 1rem 0rem 1rem;
  font-family: 'Encode Sans';
  font-size: 33px;
  max-width: 80%;
  font-weight: 700;
  line-height: 41.25px;
  letter-spacing: 0em;
  color: white;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.service-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
  color: white;
  width: 100%;
  background: rgba(114, 165, 216, 0.7);
  font-family: 'Encode Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 25px;
}
a {
  color: white;
}
</style>